<template>
  <div class="modal-container-el">

    <!--Can trigger the modal from inside it-->
    <slot name="trigger-el" :trigger="openModal"></slot>

    <v-dialog
      v-model="showDialog"
      scrollable
      :max-width="maxWidth"
      :fullscreen="fullscreen"
    >
      <v-card>

        <v-card-title class="text-h5 grey lighten-2 d-flex w-100" v-if="!!title">
          {{ title }}
          <v-btn icon class="ml-auto" @click="handleCancel"> <v-icon>mdi-close</v-icon> </v-btn>
        </v-card-title>
        <v-divider/>
        <v-card-text :style="!fullscreen ? 'max-height: 45vh' : ''" class="mt-3 pt-2">
          <slot />
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <div class="d-flex justify-end" style="width: 100%">

            <v-btn
              color="blue darken-1"
              @click="handleCancel"
              class="mr-3"
              outlined
              v-if="showCancelButton"
              :disabled="dataSubmitting"
            >
              {{ cancelBtnText }}

            </v-btn>

            <v-btn
              v-if="!hideConfirmButton"
              color="primary"
              @click="handleAction"
              :loading="dataSubmitting"
              :disabled="submitButtonDisabled || dataSubmitting"
            >
              {{ okBtnText }}
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {

  name: 'Modal',

  props: {

    showAction: {
      type: Boolean,
      default: false
    },

    hideConfirmButton: {
      type: Boolean,
      default: false
    },

    title: {
      type: String,
    },

    submitButtonLoading:{
      type: Boolean,
      default: false
    },

    okBtnText: {
      type: String,
      default: 'OK'
    },

    cancelBtnText: {
      type: String,
      default: 'Cancel'
    },

    submitButtonDisabled: {
      type: Boolean,
      default: false,
    },

    showCancelButton: {
      type: Boolean,
      default: true
    },

    successCv: {
      type: Function,
      required: false
    },

    cancelCv: {
      type: Function,
      required: false
    },

    maxWidth: {
      type: String,
      default: '550px'
    },

    fullscreen: {
      type: Boolean,
      default: false,
    },
  },

  model: {
    prop: 'showAction',
    event: 'update'
  },

  data: ()=>({
    showDialog: false,
    dataSubmitting: false
  }),

  watch: {
    submitButtonLoading: {

      handler(nv){
        this.dataSubmitting = nv;
      },

      immediate: true,
      deep: true

    },

    showAction: {

      handler(nv){

        const eventName = nv? 'show' : 'hide';
        this.showDialog = nv;

        this.$emit('update', nv);
        this.$emit(eventName, nv);

      }
    },

    showDialog: {

      handler(nv){
        const eventName = nv? 'show' : 'hide';
        this.$emit('update', nv);
        this.$emit(eventName, nv);
      }
    }
  },

  methods: {
    async handleAction(){

      if(this.successCv instanceof Function){

        this.dataSubmitting = true;

        try {
          await this.successCv();
          this.dataSubmitting = false;
        }catch(e) {
          this.dataSubmitting = false;
        }

        return;
      }

      this.$emit('confirmed');
    },

    async handleCancel(){

      this.showDialog = false;

      if(this.cancelCv instanceof Function){
        await this.cancelCv();
      }

    },

    openModal() {
      this.showDialog = true;
    }
  },

};
</script>
