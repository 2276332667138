<template>
  <div class="image-crop-container">

    <slot :loadImage="loadImage" />

    <Modal
      v-model="showCropper"
      :title="title || 'Crop Image'"
      max-width="750px"
      ok-btn-text="Crop & Save"
      :success-cv="crop"
      :cancel-cv="reset"
      :fullscreen="true"
    >
      <div
        v-if="!disableTip"
        class="mb-2"
      >
        <strong class="secondary--text">NOTE: </strong>
        Image can be moved & zoomed in/out. Highlighted area will be used.
        <div>
          <small>
            <strong>Required size: <span class="secondary--text">{{ width }} x {{ height }}</span></strong> (w/h).
            When image size is less than that in any dimension, the image will be cropped by zoomed in to fit the
            required size. <strong> This may result distorted or blurred image. </strong>
            To avoid this use images with dimensions equals / more than required and avoid zooming.
          </small>
        </div>
      </div>
      <div class="upload-example">
        <Cropper
          ref="cropper"
          class="upload-example-cropper"
          :src="image.src"
          :canvas="resultImageSize"
          :stencil-size="stencil.size"
          :stencil-props="stencil.props"
          :resize-image="stencil.resize"
          image-restriction="stencil"
          :autoZoom="false"
        />
      </div>

    </Modal>
  </div>
</template>

<script>
import 'vue-advanced-cropper/dist/style.css';
import { Cropper } from 'vue-advanced-cropper';
import { getImageRealMimeType } from '@/utils/helper';
import Modal from '@/components/Utils/Modal';

export default {
  name: 'ImageCropper',
  components: {Modal, Cropper },
  props: {
    title: String,
    width: {
      type: Number,
      default: 300
    },
    height: {
      type: Number,
      default: 300
    },
    disableTip: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      showCropper: false,
      image: {
        src: null,
        type: null
      },

      latestPreview: null,

      // cropper config
      stencil: {
        size: {
          width: 300,
          height: 300
        },
        props: {
          lines: {},
          handlers: {},
          movable: false,
          scalable: false,
          // aspectRatio: 1,
        },
        resize: {
          adjustStencil: false
        }
      },

      // output image will be always like this
      resultImageSize: {
        width: 300,
        height: 300
      }
    };
  },

  watch: {
    'image.src'(nv) {
      this.showCropper = !!nv;
    },

    width: {
      immediate: true,
      handler(nv) {
        this.stencil.size.width = nv;
        this.resultImageSize.width = nv;
      },
    },

    height: {
      immediate: true,
      handler(nv) {
        this.stencil.size.height = nv;
        this.resultImageSize.height = nv;
      },
    },
  },

  methods: {

    crop() {

      const { canvas } = this.$refs.cropper.getResult();
      canvas.toBlob((blob) => {

        // remove last preview
        if (this.latestPreview) {
          URL.revokeObjectURL(this.latestPreview);
        }

        // create new preview
        const preview = URL.createObjectURL(blob);
        this.latestPreview = preview;

        // Send cropped image in event
        this.$emit('crop', { blob, preview });

        //reset after crop
        this.reset();

      }, this.image.type);
    },

    loadImage(imageFile) {

      // check if file available
      if (imageFile) {
        // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
        if (this.image.src) {
          URL.revokeObjectURL(this.image.src);
        }

        // 2. Create the blob link to the file to optimize performance:
        const blobUrl = URL.createObjectURL(imageFile);

        // Create a new FileReader to read this image binary data
        const reader = new FileReader();

        // Define a callback function to run, when FileReader finishes its job
        reader.onload = (e) => {

          this.image = {
            // Set the image source
            src: blobUrl,
            // Determine the image type to preserve it during the extracting the image from canvas:
            type: getImageRealMimeType(e.target.result, imageFile.type),
          };
        };

        // Start the reader job - read file as a data url (base64 format)
        reader.readAsArrayBuffer(imageFile);
      }
    },

    // kept as reference
    // loadImageFromEvent(event) {
    //
    //   // Reference to the DOM input element
    //   const { files } = event.target;
    //   // Ensure that you have a file before attempting to read it
    //   if (files && files[0]) {
    //     // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
    //     if (this.image.src) {
    //       URL.revokeObjectURL(this.image.src);
    //     }
    //     // 2. Create the blob link to the file to optimize performance:
    //     const blob = URL.createObjectURL(files[0]);
    //
    //     // 3. The steps below are designated to determine a file mime type to use it during the
    //     // getting of a cropped image from the canvas. You can replace it them by the following string,
    //     // but the type will be derived from the extension and it can lead to an incorrect result:
    //     //
    //     // this.image = {
    //     //    src: blob;
    //     //    type: files[0].type
    //     // }
    //
    //     // Create a new FileReader to read this image binary data
    //     const reader = new FileReader();
    //     // Define a callback function to run, when FileReader finishes its job
    //     reader.onload = (e) => {
    //       // Note: arrow function used here, so that "this.image" refers to the image of Vue component
    //       this.image = {
    //         // Set the image source (it will look like blob:http://example.com/2c5270a5-18b5-406e-a4fb-07427f5e7b94)
    //         src: blob,
    //         // Determine the image type to preserve it during the extracting the image from canvas:
    //         type: getImageRealMimeType(e.target.result, files[0].type),
    //       };
    //     };
    //     // Start the reader job - read file as a data url (base64 format)
    //     reader.readAsArrayBuffer(files[0]);
    //   }
    // },

    reset() {
      // remove last image
      this.image = {
        src: null,
        type: null
      };

      // emit clear event
      this.$emit('cancel', null);
    },
  },

  destroyed() {
    // Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
    if (this.image.src) URL.revokeObjectURL(this.image.src);

    // remove last preview
    if (this.latestPreview) URL.revokeObjectURL(this.latestPreview);
  }
};


</script>

<style lang="scss">
.upload-example {
  border: 1px solid #ddd;
  position: relative;
}

/*
Maybe you need to set the limits for the cropper sizes or its container sizes
otherwise a cropping image will try to fill all available space
*/
.upload-example-cropper {
  //max-height: 500px;
  height: 100%;
  max-width: 100%;
  background: #DDD;

  //.vue-advanced-cropper__boundaries{
  //  max-height: 500px;
  //}
}

.cropper {
  max-height: 600px;
  background: #DDD;
}
</style>
